import React from 'react'
import Container from 'react-bootstrap/Container';
import '../styles/Socials.css'
import { FaInstagram, FaTwitter, FaGithub, FaFacebook } from 'react-icons/fa'

export default function Socials() {
    return (
        <Container className='social-nav'>
            <a href="https://github.com/React-MBC">{<FaGithub />}</a>
            <a href="https://twitter.com/react_mbccet">{<FaTwitter />}</a>
            <a href="https://instagram.com/react_mbccet">{<FaInstagram />}</a>
            <a href="https://www.facebook.com/people/React-Mbccet/100086675452356/">{<FaFacebook />}</a>
        </Container>

    )
}
