import React from 'react'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import "../styles/About.css"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper';
import "swiper/css";
import "swiper/css/autoplay";
import uuid from 'react-uuid';
import { newsData } from '../assets/data/news'


function About() {
    const items = newsData.map((item) => {
        return (
            <SwiperSlide className='ab-wrapper' key={uuid()}>
                <div className='ab-cards'>
                    <h5>{item.date}</h5>
                    <p>{item.title}</p>
                </div>
            </SwiperSlide>
        )
    })


    return (
        <Container>
            <h2 className='about-container' data-aos="fade-up">About Us</h2>
            <Row className='about-main' data-aos="fade-up">
                <Col className='about-col-1'>
                    <h3>React</h3>
                    <p>We REACT from Mar Baselios Christian College of Engineering and Technology lead by the Computer Science & Engineering Department who aims to find, nurture,
                        develop and brighten the young minds amidst us.
                        Our aim is to develop the personality and technical skills through various activities based
                        on clubs under REACT. We conduct seminars, classes, workshops by accomplished personals of
                        respective fields.

                    </p>
                </Col>
                <Col className='about-col-2'>
                    <h3>Updates</h3>
                    <Swiper
                        direction={"vertical"}
                        slidesPerView={1}
                        spaceBetween={100}
                        autoplay={{ delay: 2000 }}
                        modules={[Autoplay]}
                        className="swipe-about"
                    >
                        {items}
                    </Swiper>
                </Col>
            </Row>
        </Container>
    )
}

export default About