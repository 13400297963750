import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import "../styles/Home.css"

export default function Navbar() {
    // const text = "Rising Engineers Association of Computer Science and Technology"
    return (
        <Container className="land-main">

            <Row className="text-head">
                {/* <h2 data-aos="fade-up">React</h2> */}
                <h1 data-aos="fade-up">
                    <span>R</span><span>ising</span>&nbsp;
                    <span>E</span><span>ngineers</span>&nbsp;
                    <span>A</span><span>ssociation of</span>&nbsp;
                    <span>C</span><span>omputer Science and</span>&nbsp;
                    <span>T</span><span>echnology</span>
                </h1>
                <p className="quote"><pre>“</pre>If debugging is the process of removing software bugs, then programming must be the process of putting them in.<pre>”</pre><br />- Edsger Dijkstra, computer science pioneer</p>
            </Row>
        </Container>
    )
}
