import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import '../styles/Clubs.css'
import { clubData } from "../assets/data/clubs.js"


export default function Clubs() {

    return (
        <Container>
            <h2 className='clubs' data-aos="fade-up">Clubs</h2>
            <Row className="club-container" data-aos="fade-up">
                {clubData.map((item, index) => {
                    let imgPath = JSON.stringify(item.image).split(":")[1].split("}")[0].replace(/"/g, "")
                    return (
                        <div className='card' key={index}>
                            <div className='face front'>
                                <img src={imgPath} width="70px" alt={item.name} />
                                <h3>
                                    {item.name}
                                </h3>
                            </div>
                            <div className='face back'>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    )

                })}
            </Row>
        </Container >
    )
}