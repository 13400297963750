import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FaCopyright } from 'react-icons/fa'
import "../styles/Contact.css"

import REACT from "../assets/images/logo-black.webp"


export default function Contact() {
    const form = useRef();
    const [show, setShow] = useState(true)
    const [sending, setSending] = useState(false)
    const [text, setText] = useState("")

    const sendEmail = (e) => {
        e.preventDefault();

        setSending(true)
        emailjs.sendForm('service_cmv24be', 'template_zfz71ru', form.current, '_vfl51ZFS9GIdCFwq')
            .then((result) => {
                setShow(false)
                setSending(false)
                setText("Message Sent Successfully");
            }, (error) => {
                setShow(false)
                setText("Error Sending Message... Please Try Again");
            });
    }
    return (
        <>
            <Container className='contact-container' data-aos="fade-up">
                <h2 className='contact-header'>Contact us</h2>
                <Row className='contact-row'>
                    <Col md={6} className='contact-col-2'>
                        <center>
                        </center>
                        <img src={REACT} className="nav-img" alt="Logo" width="250px" />
                        <h2>REACT</h2>
                        <h3>Department of Computer Science & Engineering</h3>
                        <p>Mar Baselios Christian College
                            of Engineering & Technology</p>
                        <p>
                            Kuttikanam, Peermade,
                            Idukki, Kerala-685531 </p>
                    </Col>
                    {show ? <Col className="contact-col-1">
                        <Form ref={form} onSubmit={sendEmail} className="form-main">
                            <Form.Control className='field' placeholder="Name:" name="from_name" required />
                            <Form.Control className='field' type="email" placeholder="name@example.com:" name="from_email" required />
                            <Form.Control className='field' placeholder="Subject:" name="subject" required />
                            <Form.Control className='field area' as="textarea" label="Message" style={{ height: '150px' }} name="message" placeholder="Message:" required />
                            <div className='btn-sub'>
                                <Button variant="outline-dark" type='send' value="Send" disabled={sending}>Send</Button>
                            </div>
                        </Form>
                    </Col>
                        :
                        <Col className="response">
                            {text}
                        </Col>}
                </Row>
            </Container >
                <div className='copyright-div'>
                    <p className='copyright'><FaCopyright /> OWL 2022. All Rights Reserved</p>
                </div>
        </>
    )
}
