export const newsData = [
    {
        date: "21-10-2022",
        title: "REACT Inauguration"
    },
    {
        date: "26-10-2022",
        title: "Quiz Competition"
    },
    {
        date: "31-10-2022",
        title: "e-Poster Making Competition"

    },
    {
        date: "17-11-2022",
        title: "Tech Talk on Basics of Programming"

    },
    {
        date: "20-11-2022",
        title: "e-Sports Tournament"
    }
]