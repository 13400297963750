import React, { useEffect } from "react";
// import { FullPage, Slide } from 'react-full-page';
import Home from "./components/js/Home";
import Clubs from "./components/js/Clubs";
import Gallery from "./components/js/Gallery";
import Nav from "./components/js/Nav";
import Socials from "./components/js/Socials";
import Contact from "./components/js/Contact";
import Projects from "./components/js/Projects";
import Aos from "aos";
import "aos/dist/aos.css";
import About from "./components/js/About";

function App() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className="App">
      <Nav />
      <Socials />
      
      <Home />

      <About />

      <Gallery />

      <Clubs />

      <Projects />

      <Contact />
    </div>
  );
}

export default App;
