import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import logo from "../assets/images/logo.png"
import "../styles/Nav.css"

export default function Nav() {
    return (
        <Container className='nav-main'>
            <Row className="head-main">
                <img src={logo} className="nav-img" alt="Logo" width="40px" height="45px" />
                <Row className="text-nav">
                    <a href='https://forms.gle/EbGPW2Ce7D2pSvrF8' className='button-reg' target="_blank" rel="noreferrer">Register Now</a>
                </Row>
            </Row>
        </Container>
    )
}
