import Scrn1 from "../images/Projects/scrn-1.webp"
import Scrn2 from "../images/Projects/scrn-2.webp"
import Scrn3 from "../images/Projects/scrn-3.webp"

export const projectData = [
    {
        id: "0",
        title: "Password Generator",
        tags: ["html", "css", "vanilla js"],
        path: { Scrn1 },
        code: true,
        link : "https://github.com/React-MBC/javascript-password-generation"
    },
    {
        id: "1",
        title: "Hand Detector",
        tags: ["opencv", "python"],
        path: { Scrn2 },
        code: true,
        link : "https://github.com/React-MBC/opencv-handRecognition"
    },
    {
        id: "2",
        title: "Hacktober Fest 2022",
        tags: ["Hactober", "2022"],
        path: { Scrn3 },
        code: true,
        link : "https://github.com/React-MBC/React-Hacktober-Fest-2022"
    }
]