import Dev from "../images/Icons/developer.svg"
import game from "../images/Icons/gaming.svg"
import media from "../images/Icons/media.svg"
import pro from "../images/Icons/pro-grammer.svg"
import circuit from "../images/Icons/circuit.svg"

// #FF0057

export const clubData = [
    {
        id: "0",
        name: "Media Club",
        description: "Club for Students who are interested in Photography, Videography and Editography. It gives voice to the students and provides them exposure to the media and broadcast segment.",
        image: {media}
    },
    {
        id: "1",
        name: "Gaming Club",
        description: "Club for Students who are proficient in Gaming and looking to develop their skills.Here we provide an opportunity for students to socialize and have fun together based on their hobby of playing video games.",
        image: {game}
    },
    {
        id: "2",
        name: "Developers's Club",
        description: "Here we promote all kinds of development activities.This is the best platform to polish & develop programming & technical skills. It gives an opportunity to meet new people, interact, share and gain knowledge about different programming aspects and languages.",
        image: {Dev}
    },
    {
        id: "3",
        name: "Adult Coder's Club",
        description: "In a world where competition grows by the minute and multiplies by the hour, placements have become a yardstick for excellence. Here we provide all details about new technical advancement. An Exclusive Club for final Year Students",
        image: {pro}
    },
    {
        id: "4",
        name: "Hardware Club",
        description: "Club for students who are committed to hardware development.To steer the interest of the students towards learning cutting edge technological developments in electronics and computing fields. To orient and encourage more training activities related to fusion ideas related to computing and electronics fields.",
        image: {circuit}
    }
]