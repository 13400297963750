import React from 'react';
import Container from 'react-bootstrap/Container';
import { EffectCoverflow } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/effect-coverflow";
import "../styles/Projects.css";
import { projectData } from '../assets/data/projects';
import uuid from 'react-uuid';


export default function Projects() {
    const items = projectData.map((item) => {
        let imgPath = JSON.stringify(item.path).split(":")[1].split("}")[0].replace(/"/g, "")
        // console.log(path)
        return (
            < SwiperSlide key={uuid()} className="pro-wrapper" >

                <div className='cards-pro'>
                    <img src={imgPath} alt={item.title} />
                    <h3>{item.title}</h3>
                    <p>Tags: {item.tags.map((tag, index) => {
                        const len = item.tags.length
                        let taf
                        if (index !== len - 1) {
                            taf = tag + ", "
                        }
                        else {
                            taf = tag
                        }
                        return (taf)
                    })}</p>
                    {item.code ?
                        <a href={item.link} className="button-pro">Source Code</a> : ""}
                </div>
            </SwiperSlide >
        )
    }).reverse()
    return (
        <Container className='projects'>
            <h2 className='pro-heade' data-aos="fade-up">Intro Projects</h2>
            <div data-aos="fade-up">
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={"auto"}
                    coverflowEffect={{
                        rotate: 40,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    pagination={false}
                    modules={[EffectCoverflow]}
                    className="mySwiper"
                >
                    {items}
                </Swiper>
            </div>
        </Container>
    )

}