import gal_1 from "../images/Gallery/gal-1.webp"
import gal_2 from "../images/Gallery/gal-2.webp"
import gal_3 from "../images/Gallery/gal-3.webp"
import gal_4 from "../images/Gallery/gal-4.webp"
// import gal_5 from "../images/Gallery/gal-5.jpg"

export const galleryData = [
    {
        image: {gal_1},
        title: "REACT Intro"
    },
    {
        image: {gal_2},
        title: "The REACT student interaction"
    },
    {
        image: {gal_3},
        title: "REACT Ice-Breaking"
    },
    {
        image: {gal_4},
        title: "Inaugural Committee Meeting"
    }
]