
import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import '../styles/Gallery.css'
import { galleryData } from '../assets/data/gallery';

export default function Gallery() {
    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 }
    }

    const items = galleryData.map((item) => {
        let imgPath = JSON.stringify(item.image).split(":")[1].split("}")[0].replace(/"/g, "")
        return (<div className='cards'>
            <img src={imgPath} alt={item.title}/>
            <div className='bottom-text'>{item.title}</div>
        </div>)
    })

    return (
        <Container>
            <h2 className='gallery' data-aos="fade-up">Gallery</h2>
            <Row className='gallery-container' data-aos="fade-up">
                <AliceCarousel
                    autoPlay
                    autoPlayInterval="3000"
                    infinite
                    keyboardNavigation
                    disableButtonsControls
                    responsive={responsive}
                    items={items}
                    controlsStrategy="alternate" 
                />
            </Row>
        </Container>
    )

}